<template>
  <div class="d-print-none">
    <v-badge v-show="done" class="fixed-btn fixed-cart-btn" overlap color="red" :content="totalQuantity"
      :value="totalQuantity">
      <v-btn @click="goCartIndex" fab dark class="fixed-cart-btn__vbtn">
        <v-icon>ri-shopping-cart-line</v-icon>
      </v-btn>
    </v-badge>
    <PrimaryModal :show="requestCooperation.switch" @close="requestCooperation.switch = false">
      <v-card-title class="d-flex justify-center">
        <span class="pb-5">請先與 {{ layoutProviderName }} 建立合作</span>
      </v-card-title>
      <v-card-text>
        <div class="d-flex justify-center">
          <v-btn class="mr-1" min-width="100px" color="gray" @click="requestCooperation.switch = false">取消</v-btn>
          <v-btn class="ml-1" min-width="100px" color="primary" @click="apply">申請合作</v-btn>
        </div>
      </v-card-text>
    </PrimaryModal>
  </div>
</template>

<script>
import PrimaryModal from "components/modal/primaryModal.vue";
import { mapGetters } from 'vuex'

export default {
  components: {
    PrimaryModal
  },
  data: () => ({
    done: false,
    requestCooperation: {
      switch: false
    }
  }),
  computed: {
    layoutProviderId() {
      return this.$store.getters["base/layoutProviderId"];
    },
    layoutProvider() {
      return this.$store.getters["base/layoutProvider"];
    },
    hasStore() {
      return this.$store.getters["member/hasStore"];
    },
    ...mapGetters('member', ['currMemberRole']),
    isCooperation() {
      return this.$store.getters['store/isCooperation']
    },
    storeId() {
      return this.$store.getters[`member/storeId`]
    },
    providerId() {
      return this.$route.params.providerId
    },
    totalQuantity() {
      const total = this.$store.getters['cart/totalQuantity']
      return total ? total : 0
    },
    layoutProviderName() {
      return this.$store.getters[`base/layoutProviderName`]
    },
    isLogin() {
      return this.$store.getters["token/isLogin"];
    },
  },
  created() {
    this.getCart()
  },
  methods: {
    async getCart() {
      this.done = false
      // 已登入但未註冊任何商家, 可直接顯示
      if (this.isLogin && !this.hasStore) {
        this.done = true
        return
      }
      
      if (!this.currMemberRole) {
        this.done = true
        return
      }
      if (this.currMemberRole._isManager) {
        try {
          const res = await this.$api.collection.cartApi.read(this.storeId, this.providerId)
          this.$store.dispatch(`cart/setCart`, res)
        } catch (err) {
          console.error(err)
        } finally {
          this.done = true
        }
      }
    },
    goCartIndex() {
      // 已登入但未註冊任何商家, 跳轉至商家註冊頁
      if (this.isLogin && !this.hasStore) {
        this.$router.replace({
          name: "store-register",
          params: {
            providerId: this.layoutProviderId,
          },
        })
        this.done = true
        return
      }
      
      if (!this.isCooperation) {
        this.requestCooperation.switch = true
        return
      }
      this.$router.push({ name: 'cart', params: { providerId: this.providerId } })
    },
    apply() {
      this.$root.gaLogEvent('商店_點擊_申請合作')
      this.$router.push(`/${this.layoutProvider.line_liff_id}/${this.layoutProviderId}/store/register`)
      this.requestCooperation.switch = false
    }
  },
}
</script>

<style lang="sass">
.fixed-cart-btn
  &__vbtn
    backdrop-filter: blur(180px)
    background-color: #122a4778 !important
    .v-icon
      font-weight: 200 !important
      font-size: 24px !important 
</style>